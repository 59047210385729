import './bootstrap';

import Alpine from 'alpinejs';
import Intersect from '@alpinejs/intersect'

Alpine.plugin(Intersect)

window.Alpine = Alpine;

Alpine.start();

